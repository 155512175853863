// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json_decode from "../../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as Caml_exceptions from "../../../../../node_modules/rescript/lib/es6/caml_exceptions.js";

var CannotParseUnknownRole = /* @__PURE__ */Caml_exceptions.create("CoursesCurriculum__Target.CannotParseUnknownRole");

function decode(json) {
  var unknownRole = Json_decode.field("role", Json_decode.string, json);
  var tmp;
  switch (unknownRole) {
    case "student" :
        tmp = /* Student */0;
        break;
    case "team" :
        tmp = /* Team */1;
        break;
    default:
      throw {
            RE_EXN_ID: CannotParseUnknownRole,
            _1: unknownRole,
            Error: new Error()
          };
  }
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          role: tmp,
          title: Json_decode.field("title", Json_decode.string, json),
          targetGroupId: Json_decode.field("targetGroupId", Json_decode.string, json),
          sortIndex: Json_decode.field("sortIndex", Json_decode.$$int, json),
          resubmittable: Json_decode.field("resubmittable", Json_decode.bool, json),
          prerequisiteTargetIds: Json_decode.field("prerequisiteTargetIds", (function (param) {
                  return Json_decode.array(Json_decode.string, param);
                }), json),
          reviewed: Json_decode.field("reviewed", Json_decode.bool, json),
          milestone: Json_decode.field("milestone", Json_decode.bool, json)
        };
}

function id(t) {
  return t.id;
}

function role(t) {
  return t.role;
}

function title(t) {
  return t.title;
}

function sortIndex(t) {
  return t.sortIndex;
}

function targetGroupId(t) {
  return t.targetGroupId;
}

function prerequisiteTargetIds(t) {
  return t.prerequisiteTargetIds;
}

function resubmittable(t) {
  return t.resubmittable;
}

function reviewed(t) {
  return t.reviewed;
}

function milestone(t) {
  return t.milestone;
}

export {
  CannotParseUnknownRole ,
  decode ,
  id ,
  role ,
  title ,
  sortIndex ,
  targetGroupId ,
  prerequisiteTargetIds ,
  resubmittable ,
  reviewed ,
  milestone ,
}
/* No side effect */
