// Generated by ReScript, PLEASE EDIT WITH CARE

import * as User from "../shared/types/User.bs.js";
import * as React from "react";
import * as DomUtils from "../shared/utils/DomUtils.bs.js";
import * as ReactDom from "react-dom";
import * as Caml_option from "../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Json_decode from "../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as StudentTopNav from "../layouts/student/StudentTopNav.bs.js";
import * as StudentTopNav__NavLink from "../layouts/student/types/StudentTopNav__Types/StudentTopNav__NavLink.bs.js";

function decodeProps(json) {
  return [
          Json_decode.field("schoolName", Json_decode.string, json),
          Caml_option.null_to_opt(Json_decode.field("logoUrl", (function (param) {
                      return Json_decode.nullable(Json_decode.string, param);
                    }), json)),
          Json_decode.field("links", (function (param) {
                  return Json_decode.array(StudentTopNav__NavLink.decode, param);
                }), json),
          Json_decode.field("isLoggedIn", Json_decode.bool, json),
          Json_decode.field("currentUser", (function (param) {
                  return Json_decode.optional(User.decode, param);
                }), json),
          Json_decode.field("hasNotifications", Json_decode.bool, json)
        ];
}

var element = document.querySelector("#student-top-nav");

if (!(element == null)) {
  var match = decodeProps(DomUtils.parseJSONTag("student-top-nav-props", undefined));
  ReactDom.render(React.createElement(StudentTopNav.make, {
            schoolName: match[0],
            logoUrl: match[1],
            links: match[2],
            isLoggedIn: match[3],
            currentUser: match[4],
            hasNotifications: match[5]
          }), element);
}

export {
  decodeProps ,
}
/* element Not a pure module */
